// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MNYiRNbe26Eqdrp0DXF2{display:flex;flex-direction:column;justify-content:space-between;align-items:center;height:100%;width:100%;min-height:100vh;max-width:1440px;margin:0 auto;box-shadow:0 0 20px rgba(0,0,0,.1)}", "",{"version":3,"sources":["webpack://./src/pages/Layout/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,kBAAA,CACA,WAAA,CACA,UAAA,CACA,gBAAA,CACA,gBAAA,CACA,aAAA,CACA,kCAAA","sourcesContent":[".layout {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  min-height: 100vh;\n  max-width: 1440px;\n  margin: 0 auto;\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "MNYiRNbe26Eqdrp0DXF2"
};
export default ___CSS_LOADER_EXPORT___;
