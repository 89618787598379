import React from "react";

import styles from "./styles.module.scss";

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <h2>Hecho con amor por @takeout</h2>
    </div>
  );
};
