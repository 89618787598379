// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NBZvtrspG_bpWD92rA0e{margin-left:20px}.aNXozzl3NLG7bA55HtTa{width:20px;height:20px;fill:#ffb400}", "",{"version":3,"sources":["webpack://./src/components/CardReview/RatedMovies/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".rated {\n  margin-left: 20px;\n}\n\n.star__icon {\n  width: 20px;\n  height: 20px;\n  fill: #ffb400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rated": "NBZvtrspG_bpWD92rA0e",
	"star__icon": "aNXozzl3NLG7bA55HtTa"
};
export default ___CSS_LOADER_EXPORT___;
