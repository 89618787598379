// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pVhFJylp5G_R8bJxDOiA{display:grid;grid-template-columns:repeat(auto-fill, minmax(140px, 1fr));place-items:center;grid-gap:15px;margin-top:24px}.c4FLDwKwSctqTbRVCNNW{width:100%;height:225px;object-fit:cover;border-radius:4px}", "",{"version":3,"sources":["webpack://./src/components/ListMovies/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,2DAAA,CACA,kBAAA,CACA,aAAA,CAEA,eAAA,CAEA,sBACE,UAAA,CACA,YAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".listMovies {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));\n  place-items: center;\n  grid-gap: 15px;\n\n  margin-top: 24px;\n\n  &_image {\n    width: 100%;\n    height: 225px;\n    object-fit: cover;\n    border-radius: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listMovies": "pVhFJylp5G_R8bJxDOiA",
	"listMovies_image": "c4FLDwKwSctqTbRVCNNW"
};
export default ___CSS_LOADER_EXPORT___;
